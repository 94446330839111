// extracted by mini-css-extract-plugin
export var container = "projects-module--container--3JF1d";
export var project = "projects-module--project--3VubV";
export var projectInfo = "projects-module--project-info--M0BVC";
export var projectTitle = "projects-module--project-title--3hGvS";
export var projectTags = "projects-module--project-tags--2ER8E";
export var projectDescription = "projects-module--project-description--1q0aE";
export var projectImages = "projects-module--project-images--2WAeA";
export var projectImageSlider = "projects-module--project-image-slider--xsA7H";
export var projectImageContainer = "projects-module--project-image-container--2qv4Y";
export var projectImage = "projects-module--project-image--1aBKm";