import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import * as styles from "./projects.module.scss"

import BackgroundImage from "gatsby-background-image"
import { MdArrowForward } from 'react-icons/md';

const Projects = ({ limit, excludes }) => (
	<StaticQuery
		query={graphql`
			query {
					allContentfulProject(sort: {fields: [order], order: ASC}) {
						edges {
							node {
								id
								title
								slug
								tags
								images {
									fluid {
										...GatsbyContentfulFluid_withWebp
									}
								}
								introduction {
									childMarkdownRemark {
										html
									}
								}
							}
						}
					}
				}
			`
		}
		render={data =>
			<ul className={styles.container}>
				{
					data.allContentfulProject.edges.map(function (projectItem, projectIndex) {
						let project = projectItem.node

						if ((limit && projectIndex >= limit) || (excludes.length > 0 && excludes.includes(project.id))) return false

						return (
							<li key={projectIndex}>
								<Link to={`/work/${project.slug}`} className={`${styles.project} undecorated`}>
									<div className={styles.projectInfo}>
										<h4 className={styles.projectTitle}>{project.title}</h4>
										<ul className={`${styles.projectTags} pilled`}>
											{
												project.tags && project.tags.map((tag, key) =>
													<li key={key}>{tag}</li>
												)
											}
										</ul>
										<div className={styles.projectDescription} dangerouslySetInnerHTML={{ __html: project.introduction.childMarkdownRemark.html || '' }}></div>
										<div className="button">Read more <MdArrowForward /></div>
									</div>
									<div className={styles.projectImages}>
										{
											project.images && project.images.slice(0, 4).map((image, key) =>
												<div
													className={styles.projectImageContainer}
													key={key}
												>
													<BackgroundImage
														className={styles.projectImage}
														fluid={image.fluid}
													/>
												</div>
											)
										}
									</div>
								</Link>
							</li>
						)
					})
				}
			</ul>
		}
	/>
)

Projects.defaultProps = {
	limit: false,
	excludes: [],
}

export default Projects